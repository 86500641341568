exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-omc-js": () => import("./../../../src/pages/docs/omc.js" /* webpackChunkName: "component---src-pages-docs-omc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-omc-latest-js": () => import("./../../../src/pages/resources/omc/latest.js" /* webpackChunkName: "component---src-pages-resources-omc-latest-js" */),
  "component---src-pages-resources-omc-rdf-json-js": () => import("./../../../src/pages/resources/omc/rdf-json.js" /* webpackChunkName: "component---src-pages-resources-omc-rdf-json-js" */),
  "component---src-pages-resources-omc-versions-doc-js": () => import("./../../../src/pages/resources/omc/versions/doc.js" /* webpackChunkName: "component---src-pages-resources-omc-versions-doc-js" */),
  "component---src-pages-resources-omc-versions-vocab-js": () => import("./../../../src/pages/resources/omc/versions/vocab.js" /* webpackChunkName: "component---src-pages-resources-omc-versions-vocab-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-vmc-js": () => import("./../../../src/pages/vmc.js" /* webpackChunkName: "component---src-pages-vmc-js" */),
  "component---src-templates-documentation-js": () => import("./../../../src/templates/documentation.js" /* webpackChunkName: "component---src-templates-documentation-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */)
}

