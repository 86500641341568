import "focus-visible/dist/focus-visible";
import Amplify from "aws-amplify";
import awsConfig from "./src/aws-exports";
// import "./src/styles/index.css";

Amplify.configure(awsConfig);

export function onRouteUpdate() {
  window.locations = window.locations || [document.referrer];
  window.locations.push(window.location.href);
  window.previousPath = window.locations[window.locations.length - 2];
}

export function wrapPageElement({ element }) {
  const getLayout = element.type.getLayout || ((page) => page);
  return getLayout(element);
}
