// https://themera.vercel.app/
import { extendTheme } from "@chakra-ui/react";

const theme = {
  config: {
    // cssVarPrefix: "ck",
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    blue: {
      50: "#E5F6FF",
      100: "#B8E7FF",
      200: "#8AD7FF",
      300: "#5CC8FF",
      400: "#56a1d5",
      500: "#56a1d5",
      600: "#56a1d5",
      700: "#56a1d5",
      800: "#004466",
      900: "#002233",
    },
    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#888888",
      700: "#2D3748",
      800: "#1b1b1b",
      900: "#171923",
    },
  },
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
    code: `'Roboto Mono', monospace`,
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "25px",
      },
      // sizes: {
      //   md: {
      //     px: "80px",
      //   },
      // },
      variants: {
        solid: (props) => ({
          color: "white",
          bg: props.colorMode === "dark" ? "blue.500" : "blue.600",
          _hover: {
            bg: props.colorMode === "dark" ? "blue.600" : "blue.700",
          },
        }),
      },
      // defaultProps: {
      //   size: "md",
      //   variant: "outline",
      // },
    },
    FormLabel: {
      baseStyle: (props) => ({
        color: props.colorMode === "dark" ? "white" : "gray.500",
        fontSize: "14px",
        textTransform: "uppercase",
      }),
    },
    Input: {
      parts: ["field"],
      variants: {
        filled: (props) => ({
          field: {
            color: props.colorMode === "dark" ? "white" : "black",
            bgColor: props.colorMode === "dark" ? "gray.700" : "gray.100",
            border: 0,
            borderRadius: 0,
            _hover: {
              bgColor: props.colorMode === "dark" ? "gray.700" : "gray.100",
            },
            _focus: {
              bgColor: props.colorMode === "dark" ? "gray.700" : "gray.100",
            },
            "::placeholder": {
              color: "gray.400",
            },
          },
        }),
      },
    },
  },
  styles: {
    global: (props) => ({
      ".simplebar-scrollbar:before": {
        bgColor: props.colorMode === "dark" ? "white" : "black",
      },
      ".content": {
        "> :first-child": {
          marginTop: 0,
        },
        "h1, h2, h3, h4, h5, h6": {
          color: "#56a1d5",
          fontWeight: 700,
          margin: "2.5rem 0 1rem",
        },
        h1: {
          fontSize: "38px",
        },
        h2: {
          fontSize: "24px",
        },
        h3: {
          fontSize: "20px",
        },
        h4: {
          fontSize: "16px",
        },
        h5: {
          fontSize: "16px",
        },
        h6: {
          color: "#000000",
          fontSize: "16px",
          fontStyle: "italic",
        },
        p: {
          fontSize: "16px",
          lineHeight: `1.625`,
          margin: "1rem 0",
        },
        a: {
          color: "#56a1d5",
          textDecoration: "underline",
        },
        strong: {
          fontWeight: 700,
        },
        em: {
          fontStyle: "italic",
        },
        sup: {
          fontSize: "10px",
          position: "relative",
          top: "-7px",
          margin: "0 2px",

          a: {
            textDecoration: "none",
          },
        },
        ".footnotes [id*=fn-]:before": {
          left: "-32px",
        },
        ".footnotes .footnote-backref": {
          fontSize: "10px",
          position: "relative",
          top: "-7px",
          margin: "0 2px",
          textDecoration: "none",
        },
        code: {
          fontFamily: props.theme.fonts.code,
          display: "inline-block",
          padding: "0 10px",
          backgroundColor: "#f3efec",
        },
        pre: {
          position: "relative",

          code: {
            fontSize: "14px",
            position: "relative",
            display: "block",
            margin: "1.5rem 0",
            padding: "40px 25px 20px",
            backgroundColor: "#f3efec",

            "&[class*=language-]:before": {
              color: "#1a2934",
              fontWeight: 500,
              position: "absolute",
              top: 0,
              left: "20px",
              padding: "2px 10px",
              backgroundColor: "#2693cb",
            },

            "&.language-javascript:before": {
              content: '"JS"',
            },
          },

          // "code.language-javascript:before": {
          //   content: '"JS"',
          // },

          button: {
            color: "#56a1d5",
            fontFamily: props.theme.fonts.body,
            fontSize: "14px",
            fontWeight: 400,
            position: "absolute",
            top: "5px",
            right: "5px",
            display: "block",
            padding: "0 8px",
            borderRadius: "25px",
            backgroundColor: "#ffffff",
          },
        },
        "ol, ul": {
          fontSize: "16px",
          lineHeight: `1.625`,
          listStyle: "none",
          margin: "1rem 0",
          paddingLeft: "40px",

          li: {
            position: "relative",
          },

          "li:before": {
            color: "#56a1d5",
            position: "absolute",
            top: 0,
            left: "-22px",
          },
        },
        ol: {
          counterReset: "item",

          "li:before": {
            content: `counter(item) "."`,
            counterIncrement: "item",
            fontWeight: 600,
          },
        },
        ul: {
          "li:before": {
            content: '"•"',
          },
        },
        "ul.contains-task-list": {
          "li input": {
            position: "absolute",
            top: "7px",
            left: "-22px",
          },

          "li:before": {
            display: "none",
          },
        },
        blockquote: {
          margin: "1.5rem 0",
          padding: "5px 25px",
          border: "2px solid #56a1d5",
          borderLeft: "10px solid #56a1d5",

          p: {
            color: props.colorMode === "dark" ? "white" : "black",
            // color: "#000000",
            fontSize: "18px",
            fontWeight: 400,
          },

          span: {
            color: "#000000",
            fontSize: "16px",
          },
        },
        table: {
          width: "100%",
          margin: "1.5rem 0",

          thead: {
            bgColor: props.colorMode === "dark" ? "#56a1d5" : "#1a2934",
          },

          "th, td": {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "1.3",
            textAlign: "left",
            verticalAlign: "top",
            padding: "10px 25px",
          },

          th: {
            color: "#ffffff",
            fontWeight: 700,
          },

          td: {
            color: props.colorMode === "dark" ? "white" : "black",
            borderRight: "2px solid",
            borderBottom: "2px solid",
            borderColor: props.colorMode === "dark" ? "#56a1d5" : "#1a2934",
          },

          "td:last-child": {
            borderRight: "none",
          },
        },
      },
    }),
  },
};

export default extendTheme(theme);
